import { wrap } from "../_"

const pfx = "/upload"

export default (a: NuxtAxiosInstance) => ({
  uploadTmpFile (blobOrFile: Blob | File, name = "", cfg?: AxiosConfig) {
    const data = new FormData()
    const fileName = name || (blobOrFile as File).name || "file"
    data.append("upload", blobOrFile, fileName)
    return wrap(
      a.$post(`${pfx}`, data,
        {
          headers: { "Content-Type": "multipart/form-data" },
          timeout: 10 * 60 * 1000,
          ...cfg,
        }))
  },

  getProxiedFile: (url: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/proxy`,
      { params: { url }, responseType: "blob", ...cfg })),
})

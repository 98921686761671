import { wrap } from "../_"

const pfx = "/news"

export default (a: NuxtAxiosInstance) => ({
  getNews: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  getNewsItem: (itemAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${itemAlias}`,
      { cache: true, ...cfg })),
})

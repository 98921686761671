// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y\\+3yt000{grid-gap:var(--spacing-md) 20rem;display:grid;gap:var(--spacing-md) 20rem;grid-template-columns:repeat(2,auto);grid-template-rows:repeat(4,auto);justify-content:flex-start;margin:0 auto;max-width:var(--content-width);padding-bottom:4rem;width:100%}@media(max-width:1324.98px){.y\\+3yt000{padding:0 var(--spacing-lg) 4rem var(--spacing-lg)}}@media(max-width:1023.98px){.y\\+3yt000{gap:var(--spacing-md);justify-content:space-between}}@media(max-width:667.98px){.y\\+3yt000{align-items:center;display:flex;flex-direction:column;padding:0 var(--spacing-md) 4rem var(--spacing-md);text-align:center}}.jjT0DghW{grid-row:1/4}@media(max-width:667.98px){.jjT0DghW{order:1}}.lkK4bXcS{color:var(--color-black);display:block;font-size:.75rem;font-weight:600;text-transform:uppercase}.lTSA9jjs{font-size:.88rem}.BjN54d0D{color:var(--color-primary);display:block;font-size:max(1.13rem,min(2vw,1.5rem))}.KXElLcG\\+{display:flex;gap:var(--spacing-xl)}@media(max-width:947.98px){.KXElLcG\\+{flex-direction:column;gap:0}}.NNc2EDgv{color:var(--color-primary);display:flex;font-size:.88rem;gap:var(--spacing-xl)}@media(max-width:947.98px){.NNc2EDgv{flex-direction:column;gap:var(--spacing-sm)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "y+3yt000",
	"logo": "jjT0DghW",
	"subtitle": "lkK4bXcS",
	"text": "lTSA9jjs",
	"link": "BjN54d0D",
	"phones": "KXElLcG+",
	"policy": "NNc2EDgv"
};
module.exports = ___CSS_LOADER_EXPORT___;

/* eslint-disable import/no-duplicates */
import ru from "date-fns/locale/ru"
import format from "date-fns/format"
import formatInTimeZone from "date-fns-tz/formatInTimeZone"
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset"
/* eslint-enable import/no-duplicates */

export const DEFAULT_LOCALE = "ru"
export const DEFAULT_TZ = "Europe/Moscow"
export const DEFAULT_TZ_OFFSET_MS = getTimezoneOffset(DEFAULT_TZ)
export const getLocalTzOffsetMs = () =>
  new Date().getTimezoneOffset() * 60 * 1000

export function formatDate (
  utcTs: number,
  pattern: string,
  locale = DEFAULT_LOCALE,
  tz = DEFAULT_TZ,
): string {
  const locale_ = locale === "ru" ? ru : undefined
  return tz
    ? formatInTimeZone(utcTs, tz, pattern, { locale: locale_ })
    : format(utcTs, pattern, { locale: locale_ })
}

export function getRange (
  start: number,
  finish: number,
  locale = DEFAULT_LOCALE,
  tz = DEFAULT_TZ,
) {
  const fmt = (t: number) => formatDate(t, "HH:mm", locale, tz)
  return `${fmt(start)} — ${fmt(finish)}`
}

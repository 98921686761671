import { getLs, storageKeys } from "@/utils/storage"

const plugin: NuxtPlugin = ({ store }) => {
  // Имперсонализация
  store.commit("user/SET_PREVIOUS_TOKEN", getLs(storageKeys.previousToken))

  // Локальные настройки
  // store.dispatch("user/FETCH_PREFS")

  // Определяем показывать или нет кнопку "Назад"
  store.commit("app/SET_HISTORY_LEN", window.history.length)
}
export default plugin

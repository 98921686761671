import { onLoad } from "@/utils/_"

export default function ({ isDev, store }) {
  if (isDev) return
  const id = store.state.app.event?.metadata?.metrics?.googleTagManager || ""
  if (!id) return

  onLoad(() => {
    const script = document.createElement("script")
    script.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');
    `
    document.body.appendChild(script)
  })
}

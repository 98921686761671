// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gLU\\+p50m{-webkit-backdrop-filter:blur(2rem);backdrop-filter:blur(2rem);display:none;height:100vh;left:0;padding:12rem 0 8rem;position:fixed;top:0;width:100%;z-index:10}.htSE0loJ{display:flex;flex-direction:column;justify-content:space-between}.T8z9zti0{position:relative;z-index:15}.kr-mN3z2{border-bottom:2px solid var(--color-primary);height:1rem;position:relative;width:2.5rem}.kr-mN3z2:before{top:50%}.kr-mN3z2:after,.kr-mN3z2:before{background-color:var(--color-primary);content:\"\";height:2px;left:0;position:absolute;width:100%}.kr-mN3z2:after{top:0}.tPfo8vt6{font-size:.75rem;font-weight:600;margin-top:.5rem;text-transform:uppercase}.mw2ZNnMu .kr-mN3z2{border-color:transparent}.mw2ZNnMu .kr-mN3z2:before{transform:rotate(30deg)}.mw2ZNnMu .kr-mN3z2:after{top:50%;transform:rotate(-30deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"content": "gLU+p50m",
	"modalOpen": "htSE0loJ",
	"menuBtn": "T8z9zti0",
	"menuBtnBurger": "kr-mN3z2",
	"menuBtnText": "tPfo8vt6",
	"menuBtnOpen": "mw2ZNnMu"
};
module.exports = ___CSS_LOADER_EXPORT___;

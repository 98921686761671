import { wrap } from "../_"

type CreateAppData = any // TODO
type UpdateAppData = any // TODO

const pfx = "/exhibition/application"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/favorite`,
      { ...cfg })),

  addFavoriteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${appId}/favorite`, {},
      { ...cfg })),

  removeFavoriteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/${appId}/favorite`,
      { ...cfg })),

  createApp: (data: CreateAppData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/my`, data,
      { ...cfg })),

  updateMyApp: (data: UpdateAppData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}/my`, data,
      { ...cfg })),

  getMyApp: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),

  getApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  getApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${appId}`,
      { cache: true, ...cfg })),
})

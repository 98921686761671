import { wrap } from "../_"

const pfx = "/page"

export default (a: NuxtAxiosInstance) => ({
  getPages: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  getPage: (path: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${path}`,
      { cache: true, ...cfg })),
})

import { wrap } from "../_"

const pfx = "/highlight"

export default (a: NuxtAxiosInstance) => ({
  getHighlights: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  getHighlight: (highlightAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${highlightAlias}`,
      { cache: true, ...cfg })),
})

import { wrap, wrapGql } from "../_"
import { Contestants } from "@/types/api/contestants"

type UpdateUserData = any // TODO
type CreateUserData = any // TODO
type CreateUserQuickData = any // TODO

interface SendTokenData { email: string, status?: string }

const pfx = "/user"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/favorite`,
      { ...cfg })),

  addFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${userId}/favorite`, {},
      { ...cfg })),

  removeFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/${userId}/favorite`,
      { ...cfg })),

  getUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  updateUser: (data: UpdateUserData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}`, data,
      { ...cfg })),

  getUser: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),

  getCountries: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/countries`,
      { cache: true, ...cfg })),

  getColleague: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/colleague/${userId}`,
      { ...cfg })),

  updateColleague: (userId: string, data: UpdateUserData, cfg?: AxiosConfig) =>
    wrap(
      a.$patch(`${pfx}/colleague/${userId}`, data,
        { ...cfg })),

  createColleague: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/colleague`, data,
      { ...cfg })),

  createUser: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),

  sendToken: (data: SendTokenData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/registration-token`, data,
      { ...cfg })),

  getTokenData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/registration-token/${token}`,
      { ...cfg })),

  createUserQuick: (data: CreateUserQuickData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/quick-registration`, data,
      { ...cfg })),

  getPreviousData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/previous-user/${token}`,
      { ...cfg })),

  getA5KUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/a5k`,
      { cache: true, ...cfg })),

  getRecommendedUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/recommended`,
      { ...cfg })),

  getTeam: (cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listMyTeamByParticipants {
            id
            # creationTime
            # updateTime
            # author
            nominants {
              id
              # creationTime
              # updateTime
              # author
              nameNominant
              nomination {
                title {
                  current
                }
              }
              typeNominant {
                title {
                  current
                }
              }
              formatNominant {
                title {
                  current
                }
              }
              descriptionNominant
              phone
              email
              site
              region {
                region
                result
              }
              logo {
                url
              }
            }
            participants {
              id
              firstName {
                current
              }
              lastName {
                current
              }
            }
            region
            teamName
          }
        }
      `,
    }, { ...cfg }),
    "listMyTeamByParticipants",
  ),

  listLikedNominants: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listMyLike (limit: 1000) {
            items {
              id
              nominant {
                id
                nomination {
                  title {
                    current
                  }
                }
              }
            }
          }
        }
      `,
    }),
    "listMyLike",
  ),

  likeNominant: (nominantId: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($nominantId: UUID!) {
          createLike (nominant: $nominantId) {
            id
          }
        }
      `,
      variables: { nominantId },
    }),
    "createLike",
  ),

  unlikeNominant: (likeId: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($likeId: UUID!) {
          deleteLike (id: $likeId)
        }
      `,
      variables: { likeId },
    }),
    "deleteLike",
  ),

  getContestants: (variables = {}) => wrapGql(
    a.$post("/graphql", {
      query: `
        query (
          $limit: Int! = 25,
          $page: Int! = 1,
          $filters: [Filter!]! = [],
          $sorters: [Sorter!]! = []
        ) {
          listContestants (
            limit: $limit
            page: $page
            filters: $filters
            sorters: $sorters
          ) {
            items {
              id
              state
              category
              name
              lastname
              patronymic
              region
              birthdate
              email
              picture { url }
              description
              creationTime
            }
            pagination {
              total
              page
              pages
              limit
            }
          }
        }
      `,
      variables,
    }),
    "listContestants",
  ),

  getContestantsTotal: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query (
          $limit: Int! = 25,
          $page: Int! = 1,
          $filters: [Filter!]! = [],
          $sorters: [Sorter!]! = []
        ) {
          listContestants (
            limit: $limit
            page: $page
            filters: $filters
            sorters: $sorters
          ) {
            items { state }
            pagination { total }
          }
        }
      `,
      variables: {
        limit: 1,
        filters: [{ name: "state", value: "third" }],
      },
    }),
    "listContestants",
  ),

  getContestantsRegions: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listContestantRegion {
            items
          }
        }
      `,
    }),
    "listContestantRegion",
  ),

  createContestants: (data: Contestants) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation (
          $lastname: String!
          $name: String!
          $patronymic: String
          $region: String!
          $birthdate: DateTime!
          $phonenumber: String!
          $email: String!
          $picture: UploadPath!
          $description: String!
          $agreement: Boolean!
          $parent: String
          $parentlastname: String
          $parentfirstname: String
          $parentpatronymic: String
          $parentemail: String
          $parentphone: String
          $captcha: String
          $state: String
          $category: String
        ) {
          createContestants (
            lastname: $lastname
            name: $name
            patronymic: $patronymic
            region: $region
            birthdate: $birthdate
            phonenumber: $phonenumber
            email: $email
            picture: $picture
            description: $description
            agreement: $agreement
            parent: $parent
            parentlastname: $parentlastname
            parentfirstname: $parentfirstname
            parentpatronymic: $parentpatronymic
            parentemail: $parentemail
            captcha: $captcha
            state: $state
            category: $category
          ) {
            id
          }
        }
      `,
      variables: { ...data },
    }),
    "createContestants",
  ),

  changeContestantsState: (id: String, state: String) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation (
          $contestant: UUID!,
          $state: String!
        ) {
          changeContestantsState (
            contestant: $contestant,
            state: $state
          ) {
            state
          }
        }
      `,
      variables: {
        contestant: id,
        state,
      },
    }),
    "changeContestantsState",
  ),

  getWinners: (cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listWinner {
            items {
              id
              name
              region
              age
              picture { url }
            }
          }
        }
      `,
    }, { ...cfg }),
    "listWinner",
  ),

  getProjectState: (email: String) => wrapGql(
    a.$post("/graphql", {
      query: `
        query ($email: String) {
          getProjectState (email: $email)
        }
      `,
      variables: { email },
    }),
    "getProjectState",
  ),

  verifyContestant: (token: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($contestant: UUID!) {
          verifyContestantEmail (contestant: $contestant)
        }
      `,
      variables: { contestant: token },
    }),
    "verifyContestantEmail",
  ),
})

import { wrap } from "../_"

const pfx = "/partner"

export default (a: NuxtAxiosInstance) => ({
  getPartners: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  getPartnerData: (partnerAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${partnerAlias}`,
      { cache: true, ...cfg })),

  getPartnersGroups: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/group`,
      { cache: true, ...cfg })),
})

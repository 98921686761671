// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShV2e0TP{align-items:flex-start;display:flex;flex-direction:column;gap:var(--spacing-lg);margin:0 auto;max-width:var(--content-width);padding:var(--spacing-lg) 0;width:100%}@media(max-width:1324.98px){.ShV2e0TP{padding:var(--spacing-lg)}}@media(max-width:667.98px){.ShV2e0TP{padding:var(--spacing-md)}}.Gf86HLk5{align-items:center;color:var(--color-black);display:flex;font-size:1.25rem;line-height:1.5;transition:color .15s;white-space:nowrap}@media(hover:hover){.Gf86HLk5:hover{color:var(--color-primary)}}@media(hover:none){.Gf86HLk5:active{color:var(--color-primary)}}.Gf86HLk5.nuxt-link-active{color:var(--color-primary);cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "ShV2e0TP",
	"link": "Gf86HLk5"
};
module.exports = ___CSS_LOADER_EXPORT___;

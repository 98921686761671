
import InlineSvg from "vue-inline-svg"

export default {
  components: { InlineSvg },

  props: {
    name: { type: String, required: true },
    paint: { type: Array, default: () => [] },
    title: { type: String, default: "" },
  },
}

import createPrivateChatNotifications from "@/utils/private-chat-notifications"

const plugin: NuxtPlugin = (ctx, inject) => {
  inject("privateChatNotifications", createPrivateChatNotifications(ctx))
}
export default plugin

type PrivateChatNotifications = ReturnType<typeof plugin>
declare module "vue/types/vue" {
  interface Vue { $privateChatNotifications: PrivateChatNotifications }
}
declare module "@nuxt/types" {
  interface NuxtAppOptions { $privateChatNotifications: PrivateChatNotifications }
  interface Context { $privateChatNotifications: PrivateChatNotifications }
}
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> { $privateChatNotifications: PrivateChatNotifications }
}

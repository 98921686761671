
import { mapState } from "vuex"
import pickBy from "lodash/pickBy"

export default {
  computed: {
    ...mapState("app", ["event"]),
    links () { return pickBy(this.event?.links || {}, Boolean) },
  },
}

import { wrapGql } from "../_"
import type { Filters } from "@/types/api/gql"

export default (a: NuxtAxiosInstance) => ({
  getNominantRating: (nominantId: string, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query ($nominantId: UUID!) {
          listMyRating (
            filters: [{ name: "nominant.id", value: $nominantId }]
          ) {
            items {
              nominant {
                id
              }
            }
          }
        }
      `,
      variables: { nominantId },
    }, { ...cfg }),
    "listMyRating",
  ),

  createRating: (variables: Record<string, any>, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation(
          $status: UUID!
          $question1: String
          $question10: String
          $question11: String
          $question12: String
          $question13: String
          $question14: String
          $question15: String
          $question16: String
          $question17: String
          $question18: String
          $question19: String
          $question2: String
          $question20: String
          $question21: String
          $question22: String
          $question23: String
          $question24: String
          $question25: String
          $nominant: UUID!
          $question27: String
          $question28: String
          $question29: String
          $question3: String
          $question30: String
          $question31: String
          $question32: String
          $question33: String
          $question34: String
          $question35: String
          $question36: String
          $question37: String
          $question4: String
          $question5: String
          $question6: String
          $question7: String
          $question8: String
          $question9: String
          $question26: String
        ) {
          createRating (
            status: $status
            question1: $question1
            question10: $question10
            question11: $question11
            question12: $question12
            question13: $question13
            question14: $question14
            question15: $question15
            question16: $question16
            question17: $question17
            question18: $question18
            question19: $question19
            question2: $question2
            question20: $question20
            question21: $question21
            question22: $question22
            question23: $question23
            question24: $question24
            question25: $question25
            nominant: $nominant
            question27: $question27
            question28: $question28
            question29: $question29
            question3: $question3
            question30: $question30
            question31: $question31
            question32: $question32
            question33: $question33
            question34: $question34
            question35: $question35
            question36: $question36
            question37: $question37
            question4: $question4
            question5: $question5
            question6: $question6
            question7: $question7
            question8: $question8
            question9: $question9
            question26: $question26
          )
        }
      `,
      variables,
    }, { ...cfg }),
    "createRating",
  ),

  listNominant: (page = 1, filters: Filters = []) => wrapGql(
    a.$post("/graphql", {
      query: `
        query (
          $page: Int!
          $filters: [Filter!]
        ) {
          listNominant (
            page: $page
            limit: 12
            filters: $filters
          ) {
            items {
              id
              nameNominant
              nomination {
                id
                title {
                  current
                }
              }
              typeNominant {
                id
                title {
                  current
                }
              }
              formatNominant {
                id
                title {
                  current
                }
              }
              # descriptionNominant
              # phone
              # email
              # site
              region {
                region
                city
              }
              logo {
                url
              }
              finalist
            }
            pagination {
              page
              pages
              limit
              total
            }
          }
        }
      `,
      variables: { page, filters },
    }),
    "listNominant",
  ),

  getNominant: (id: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        query (
          $id: UUID!
        ) {
          listNominant (
            limit: 1
            filters: [{ name: "id", value: $id }]
          ) {
            items {
              id
              nameNominant
              nomination {
                title {
                  current
                }
              }
              typeNominant {
                title {
                  current
                }
              }
              formatNominant {
                title {
                  current
                }
              }
              descriptionNominant
              phone
              email
              site
              region {
                region
                result
              }
              logo {
                url
              }
              finalist
            }
          }
        }
      `,
      variables: { id },
    }),
    "listNominant",
  ),

  listNominantRegion: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listNominantRegion
        }
      `,
    }),
    "listNominantRegion",
  ),
})

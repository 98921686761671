import { wrap } from "../_"

interface ChangePasswordData { token: string, newPassword: string }

const pfx = "/user/password-recovery"

export default (a: NuxtAxiosInstance) => ({
  initPasswordRecovery: (email: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/init`, { email },
      { ...cfg })),

  changePassword: (data: ChangePasswordData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/change`, data,
      { ...cfg })),
})

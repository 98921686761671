import { wrap } from "../_"

interface CreateMeetingData {
  title: string
  description: string
  attachment: string
  timeSlot: string
  invitee: string
}

const pfx = "/meeting"

export default (a: NuxtAxiosInstance) => ({
  getTimeSlots: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/time-slot`,
      { params: { invitee: userId }, cache: true, ...cfg })),

  createMeeting: (data: CreateMeetingData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),

  getUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { ...cfg })),

  agreeMeeting: (meetingId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${meetingId}/agree`, {},
      { ...cfg })),

  changeMeetingTime: (meetingId: string, timeSlot: string, cfg?: AxiosConfig) =>
    wrap(
      a.$post(`${pfx}/${meetingId}/change-time`, { timeSlot },
        { ...cfg })),

  refuseMeeting: (meetingId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${meetingId}/refuse`, {},
      { ...cfg })),

  getMyMeetings: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),
})

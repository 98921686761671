// import get from "lodash/get"
// import has from "lodash/has"
// import Vue from "vue"

export const state = () => ({
  // activeChat: null,
  // activeDay: null,
  // activeHall: null,
  // chatId: null,
  halls: [],
  // loading: true,
  // session: {},
  // sessionPolls: [],
  // sessionQuestions: [],
  // sessionTracks: [],
  // speakers: [],
  // tracks: [],
})

export const getters = {
  mainHallAlias: state => state.halls[0]?.alias || "",
  isAsideOpen: (_state, _getters, rootState) =>
    rootState.user.prefs.isLiveAsideOpen,
  // activeChat: state => state.activeChat,
  // activeCode: (_state, getters) =>
  //   get(getters.session, "track.liveData.embedCode", null),
  // activeDay: state => state.activeDay,
  // activeHall: state => state.activeHall,
  // halls: state => state.halls,
  // hall: (_state, getters) =>
  //   getters.halls.find(hall => hall.alias === getters.activeHall),
  // loading: state => state.loading,
  // session: state => state.session,
  // sessionPolls: state => state.sessionPolls,
  // sessionQuestions: state => state.sessionQuestions,
  // sessionTracks: state => state.sessionTracks,
  // speakers: state => state.speakers,
  // track: (_state, getters) => get(getters.session, "track", {}),
  // tracks: state => state.tracks,
}

export const mutations = {
  // SET_ACTIVE_CHAT (state, payload) { state.activeChat = payload },
  // SET_ACTIVE_DAY (state, payload) { state.activeDay = payload },
  // SET_ACTIVE_HALL (state, payload) { state.activeHall = payload },
  SET_HALLS (state, halls) { state.halls = halls },
  // SET_LOADING (state, payload) { state.loading = payload },
  // SET_SESSION (state, payload) { state.session = payload },
  // SET_SESSION_QUESTIONS (state, payload) { state.sessionQuestions = payload },
  // SET_SESSION_POLLS (state, payload) { state.sessionPolls = payload },
  // SET_SESSION_TRACKS (state, payload) { state.sessionTracks = payload },
  // SET_SPEAKERS (state, payload) { state.speakers = payload },
  // SET_TRACKS (state, tracks) { state.tracks = tracks },
  // SET_VOTE_FOR_SESSION (state) {
  //   Vue.set(state.session, "votes", get(state.session, "votes") + 1)
  //   Vue.set(state.session, "voted", true)
  // },
  // SET_VOTE_FOR_QUESTION (state, id) {
  //   const questions = state.sessionQuestions
  //   const index = questions.findIndex(entry => entry.id === id)
  //   const entry = questions[index]
  //   entry.voted = true
  //   entry.votes = entry.votes + 1
  // },
}

export const actions = {
  // async fetchTracks ({ commit }) {
  //   const [res, err] = await this.$api.program.getTracks()
  //   if (err) return console.error(err)
  //   const tracks = this.$api.helpers.getList(res)
  //   commit("SET_TRACKS", tracks)
  // },

  async fetchHalls ({ commit }) {
    const [res, err] = await this.$api.program.getHalls()
    if (err) return console.error(err)
    const halls = this.$api.helpers.getList(res)
    commit("SET_HALLS", halls)
    // commit("SET_ACTIVE_HALL", halls[0]?.alias || null)
  },

  // async FETCH_SESSION ({ commit, dispatch, rootState }, hall) {
  //   commit("SET_LOADING", true)
  //   commit("SET_ACTIVE_CHAT", null)
  //   const [res, err] = await this.$api.program.getHallLiveSession(hall)
  //   if (err) {
  //     console.error(err)
  //     return {}
  //   }
  //   const session = res || {}
  //   const day = session.track.day.alias
  //   commit("SET_SESSION", session)
  //   commit("SET_LOADING", false)
  //   commit("SET_ACTIVE_DAY", day)
  //   if (this.app.router.currentRoute.path !== "/") {
  //     await dispatch("FETCH_SESSION_TRACKS")
  //     await dispatch("FETCH_SPEAKERS")
  //     if (rootState.auth.loggedIn) await dispatch("FETCH_SESSION_POLLS")
  //     await dispatch("FETCH_SESSION_QUESTIONS")
  //     commit("SET_ACTIVE_CHAT", session.track.publicChat || null)
  //   }
  //   commit("SET_LOADING", false)
  //   return session
  // },

  // async UPDATE_SESSION ({ commit, dispatch }, hall) {
  //   const [res, err] = await this.$api.program.getHallLiveSession(hall)
  //   if (err) {
  //     console.error(err)
  //     return {}
  //   }
  //   const session = res || {}
  //   const day = get(session, "track.day.alias")
  //   commit("SET_SESSION", session)
  //   commit("SET_ACTIVE_DAY", day)
  //   if (this.app.router.currentRoute.path !== "/") {
  //     await dispatch("FETCH_SESSION_TRACKS")
  //     await dispatch("FETCH_SPEAKERS")
  //     await dispatch("FETCH_SESSION_POLLS")
  //     await dispatch("FETCH_SESSION_QUESTIONS")
  //   }
  //   return session
  // },

  // async FETCH_SESSION_BY_CODE ({ commit, dispatch }, code) {
  //   commit("SET_LOADING", true)
  //   const [res, err] = await this.$api.program.getSessionByCode(code)
  //   if (err) {
  //     commit("SET_LOADING", false)
  //     return console.error(err)
  //   }
  //   if (res) {
  //     commit("SET_SESSION", res)
  //     commit("SET_ACTIVE_DAY", res.track.day.alias)
  //     if (this.app.router.currentRoute.path !== "/") {
  //       await dispatch("FETCH_SESSION_TRACKS")
  //       await dispatch("FETCH_SPEAKERS")
  //       await dispatch("FETCH_SESSION_POLLS")
  //       await dispatch("FETCH_SESSION_QUESTIONS")
  //     }
  //     commit("SET_LOADING", false)
  //     return res
  //   }
  //   commit("SET_LOADING", false)
  // },

  // async FETCH_SESSION_TRACKS ({ commit, getters }) {
  //   if (has(getters, "track.id")) {
  //     const [res, err] = await this.$api.program.getSessions({
  //       params: { "filter[track]": getters.track.id },
  //     })
  //     if (err) {
  //       console.error(err)
  //     } else {
  //       commit("SET_SESSION_TRACKS", this.$api.helpers.getList(res))
  //       commit("SET_LOADING", false)
  //     }
  //   } else {
  //     commit("SET_SESSION_TRACKS", [])
  //     commit("SET_LOADING", false)
  //   }
  // },

  // async SET_ACTIVE_HALL ({ commit, dispatch }, alias) {
  //   commit("SET_ACTIVE_HALL", alias)
  //   commit("SET_LOADING", true)
  //   await dispatch("FETCH_SESSION", alias)
  // },

  // async VOTE_FOR_SESSION ({ commit, getters }) {
  //   const [, err] =
  //     await this.$api.program.addSessionVote(getters.session.alias, 1)
  //   if (err) {
  //     console.error(err)
  //   } else {
  //     commit("SET_VOTE_FOR_SESSION")
  //     return true
  //   }
  // },

  // async VOTE_FOR_QUESTION ({ commit }, id) {
  //   const [, err] = await this.$api.program.addQuestionVote(id, 1)
  //   if (err) {
  //     console.error(err)
  //   } else {
  //     commit("SET_VOTE_FOR_QUESTION", id)
  //     return true
  //   }
  // },

  // async FETCH_SPEAKERS ({ commit, getters }) {
  //   const [res, err] = await this.$api.person.getSpeakers({
  //     params: {
  //       "filter[session]": getters.session.alias,
  //       "sorters[session]": "asc",
  //     },
  //   })
  //   if (err) console.error(err)
  //   else commit("SET_SPEAKERS", this.$api.helpers.getList(res))
  // },

  // async FETCH_SESSION_POLLS ({ commit, getters }) {
  //   const [res, err] = await this.$api.poll.getSessionPolls(getters.session.id)
  //   if (err) console.error(err)
  //   else commit("SET_SESSION_POLLS", this.$api.helpers.getList(res))
  // },

  // async ANSWER_POLL ({ dispatch }, { poll, answer }) {
  //   const [, err] = await this.$api.poll.answerPoll(poll, answer)
  //   if (err) console.error(err)
  //   else await dispatch("FETCH_SESSION_POLLS")
  // },

  // async FETCH_SESSION_QUESTIONS ({ commit, getters }) {
  //   const [res, err] =
  //     await this.$api.program.getSessionQuestions(getters.session.alias)
  //   if (err) console.error(err)
  //   else commit("SET_SESSION_QUESTIONS", this.$api.helpers.getList(res))
  // },

  // async POST_SESSION_QUESTION (_ctx, payload) {
  //   const [, err] = await this.$api.program.createSessionQuestion(payload)
  //   if (err) {
  //     console.error(err)
  //     return { errors: true }
  //   } else { return { success: true } } // FIXME
  // },
}

import { wrap } from "../_"
import helpers from "../helpers"

const pfx = "/faq"

export default (a: NuxtAxiosInstance) => ({
  getFaq: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })
      .then(r => helpers.getList(r))),
})

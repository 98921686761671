// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7Qk4C4nt{width:100%}.MjLR1btY{align-items:center;display:flex;justify-content:space-between;margin:0 auto;max-width:var(--content-width);padding:var(--spacing-lg) 0}@media(max-width:1324.98px){.MjLR1btY{padding:var(--spacing-lg)}}@media(max-width:667.98px){.MjLR1btY{padding:var(--spacing-md)}}._5yrOdytA{display:flex;position:relative;z-index:15}@media(max-width:1023.98px){._5yrOdytA{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "_7Qk4C4nt",
	"content": "MjLR1btY",
	"headSponsors": "_5yrOdytA"
};
module.exports = ___CSS_LOADER_EXPORT___;

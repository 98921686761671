import { onLoad } from "@/utils/_"

export default function ({ isDev, store }) {
  if (isDev) return
  const id = store.state.app.event?.metadata?.metrics?.yandexMetrika || ""
  if (!id) return

  onLoad(() => {
    const script = document.createElement("script")
    script.text = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym("${id}", "init", {
        clickmap:true,
        trackHash:true,
        trackLinks:true,
        webvisor:true,
        accurateTrackBounce:true
      });
    `
    document.body.appendChild(script)
  })
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.container},[_c('button',{class:[
      _vm.$style.menuBtn,
      { [_vm.$style.menuBtnOpen]: _vm.isOpen }
    ],on:{"click":function($event){$event.preventDefault();_vm.isOpen = !_vm.isOpen}}},[_c('div',{class:_vm.$style.menuBtnBurger}),_vm._v(" "),_c('div',{class:_vm.$style.menuBtnText},[_vm._v("\n      "+_vm._s(_vm.isOpen ? "закрыть" : "меню")+"\n    ")])]),_vm._v(" "),_c('div',{ref:"menu",class:[
      _vm.$style.content,
      { [_vm.$style.modalOpen]: _vm.isOpen }
    ]},[_c('app-header-nav')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
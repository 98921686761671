import { wrap } from "../_"

interface CreateAppointmentData {
  session: string
  projectName: string
  meetingPurpose: string
  aboutMyself: string
  presentation: any // ?
}

const pfx = "/mentor-session"

export default (a: NuxtAxiosInstance) => ({
  createAppointment: (data: CreateAppointmentData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/appointment`, data,
      { ...cfg })),

  getSessions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session`,
      { ...cfg })),

  getMySessions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/appointment/my`,
      { ...cfg })),

  cancelSession: (sessionId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/appointment/${sessionId}/cancel`, {},
      { ...cfg })),

  changeTime: (appointmentId: string, timeSlot: string, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}/appointment/${appointmentId}`, { timeSlot },
      { ...cfg })),
})

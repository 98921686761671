// Локализация редиректов
export default ({ app, $auth }) => {
  function onLanguageSwitched () {
    const { redirect } = $auth.$storage.options
    for (const key in redirect)
      $auth.$storage.options.redirect[key] = app.localePath(redirect[key])
  }

  onLanguageSwitched()

  app.i18n.onLanguageSwitched = () => { onLanguageSwitched() }
}

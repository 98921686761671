import { wrap } from "../_"

interface CreateOrUpdateMyDataData {
  model: string
  number: string
  name: string
  withDriver: boolean
}

const pfx = "/car-pass"

export default (a: NuxtAxiosInstance) => ({
  getApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { ...cfg })),

  getInfo: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/information`,
      { ...cfg })),

  createApp: (data: CreateOrUpdateMyDataData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),

  deleteApp: (appId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/${appId}`,
      { ...cfg })),
})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1asTDBSz{flex-wrap:wrap;gap:2rem}@media(max-width:1324.98px){._1asTDBSz{padding:0 var(--spacing-lg)}}@media(max-width:1023.98px){._1asTDBSz{gap:1rem;justify-content:space-between}}@media(max-width:667.98px){._1asTDBSz{justify-content:center;padding:0 var(--spacing-md)}}._5pL3663N{height:6rem}@media(max-width:667.98px){._5pL3663N{height:4.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "_1asTDBSz",
	"logo": "_5pL3663N"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qUMTE6Rm{justify-content:center;min-height:100vh;overflow-x:hidden;overflow-y:auto;padding:var(--spacing-md)}.qUMTE6Rm,.LmpaVHOi{align-items:center;display:flex}.LmpaVHOi{position:relative}@media(max-width:947.98px){.LmpaVHOi{flex-direction:column}}.D6mHpBxZ{color:var(--color-bg-invert);font-size:22.63rem;font-weight:700;left:0;line-height:1;opacity:.1;pointer-events:none;position:absolute;top:0}@media(max-width:947.98px){.D6mHpBxZ{font-size:11.38rem;left:50%;top:30%;transform:translate(-50%,-50%)}}._95T7kD5a{position:relative}@media(max-width:947.98px){._95T7kD5a{transform:translateX(.5rem)}}.wP88Xsf5{color:var(--color-primary);height:13rem;position:absolute;right:1rem;top:50%;transform:translateY(-50%);width:17.63rem}@media(max-width:947.98px){.wP88Xsf5{height:6.56rem;right:.5rem;width:8.88rem}}._4Ho6IFAs{color:var(--color-bg-invert);font-size:8.13rem;font-weight:700;line-height:1.46;position:relative;-webkit-user-select:none;-moz-user-select:none;user-select:none}@media(max-width:947.98px){._4Ho6IFAs{font-size:4.06rem}}._1qHHWi2t{padding-left:var(--spacing-xxl);position:relative}@media(max-width:947.98px){._1qHHWi2t{align-items:center;display:flex;flex-direction:column;padding-left:0;padding-top:var(--spacing-lg);text-align:center}}._0KaNKvL8{color:var(--color-gray-4);font-size:1.5rem;font-weight:700;line-height:1.46;margin-bottom:1.46em;max-width:19rem}@media(max-width:947.98px){._0KaNKvL8{font-size:1.25rem;margin-bottom:.8em;max-width:100%}}.NE1PmaC3{color:var(--color-gray-4);font-size:.88rem;line-height:1.43;margin-bottom:2.14em;max-width:20rem}@media(max-width:947.98px){.NE1PmaC3{font-size:.81rem;margin-bottom:1.5em;max-width:80%}}.aFqQ4n1e{color:var(--color-primary);font-size:.88rem;font-weight:600;line-height:1.57}@media(hover:hover){.aFqQ4n1e:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.aFqQ4n1e:active{-webkit-text-decoration:underline;text-decoration:underline}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1324.98",
	"container": "qUMTE6Rm",
	"inner": "LmpaVHOi",
	"bg": "D6mHpBxZ",
	"status": "_95T7kD5a",
	"statusFrame": "wP88Xsf5",
	"statusCode": "_4Ho6IFAs",
	"info": "_1qHHWi2t",
	"title": "_0KaNKvL8",
	"description": "NE1PmaC3",
	"link": "aFqQ4n1e"
};
module.exports = ___CSS_LOADER_EXPORT___;

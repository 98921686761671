import Vue from "vue"
import { mapState } from "vuex"

interface RawMenuItem {
  url: string
  newTab: boolean
  title: Localized
}

type Menu = {
  title: RawMenuItem["title"]
  is: "a" | "nuxt-link"
  attrs: Record<string, any>
}[]

export default Vue.extend({
  computed: {
    ...mapState("app", { rawMenu: "menu" }),

    menu (): Menu {
      return this.rawMenu.map((m: RawMenuItem) => {
        let { url } = m

        const isExternal = url.startsWith("http") || url.startsWith("mailto:")
        if (!isExternal) {
          const isHash = url.includes("#")
          if (!isHash) {
            if (url[0] !== "/") url = `/${url}`
            url = this.localePath(url)
          }
        }

        const attrs: Record<string, any> =
          isExternal ? { href: url } : { to: url }
        if (m.newTab) attrs.target = "_blank"

        return {
          title: m.title,
          is: isExternal ? "a" : "nuxt-link",
          attrs,
        }
      })
    },
  },
})

import { wrap } from "../_"

const pfx = "/poll"

export default (a: NuxtAxiosInstance) => ({
  getSessionPolls: (sessionId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/${sessionId}`,
      { ...cfg })),

  answerPoll: (pollId: string, answer: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${pollId}`, { answer },
      { ...cfg })),
})

import { localize } from "@/plugins/fmt/modules/localize"
import { DEFAULT_TZ } from "@/utils/date"
import menu from "@/const/menu.json"

export const state = () => ({
  event: null,
  asideExpanded: true, // TODO: remove
  menu, // Фоллбэк
  statuses: [],
  tags: [],
  faq: [],
  historyLen: 0,
  votingClosed: false,
})

export const getters = {
  description (state, _getters, { i18n: { locale } }) {
    const description = state.event?.description
    return description ? localize(locale, description) : ""
  },

  getTitle: (state, _getters, { i18n: { locale } }) => (pageTitle = "") => {
    const title = localize(locale, state.event?.title)
    if (pageTitle)
      if (title) return `${pageTitle} · ${title}`
      else return pageTitle
    else if (title) return title
    else return ""
  },

  asideExpanded: state => state.asideExpanded, // TODO: remove

  timezone: state => state.event?.timezone || DEFAULT_TZ,
}

export const mutations = {
  SET_EVENT (state, event) { state.event = event },
  SET_ASIDE (state, aside) { state.asideExpanded = aside }, // TODO: remove
  SET_MENU (state, menu) { state.menu = menu },
  SET_STATUSES (state, statuses) { state.statuses = statuses },
  SET_TAGS (state, tags) { state.tags = tags },
  SET_FAQ (state, faq) { state.faq = faq },
  SET_HISTORY_LEN: (state, historyLen) => { state.historyLen = historyLen },
  SET_VOTING_CLOSED: (state, votingClosed) => { state.votingClosed = votingClosed },
}

export const actions = {
  async FETCH_EVENT ({ commit }) {
    const [res, err] = await this.$api.event.getEventData()
    if (err) console.error(err)
    else commit("SET_EVENT", res)
  },

  async FETCH_MENU ({ commit }) {
    const [res, err] = await this.$api.event.getMenu()
    if (err) console.error(err)
    else commit("SET_MENU", this.$api.helpers.getList(res))
  },

  async FETCH_STATUSES ({ state, commit }) {
    if (state.statuses.length) return
    const [res, err] = await this.$api.event.getStatuses()
    if (err) console.error(err)
    else commit("SET_STATUSES", this.$api.helpers.getList(res))
  },

  async FETCH_TAGS ({ state, commit }) {
    if (state.tags.length) return
    const [res, err] = await this.$api.tag.getTags()
    if (err) console.error(err)
    else commit("SET_TAGS", this.$api.helpers.getList(res))
  },

  async FETCH_FAQ ({ state, commit }) {
    if (state.faq.length) return
    const [res, err] = await this.$api.faq.getFaq()
    if (err) console.error(err)
    else commit("SET_FAQ", res || [])
  },
}

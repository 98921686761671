import { wrap } from "../_"

const pfx = "/runet-id"

export default (a: NuxtAxiosInstance) => ({
  searchUsers: (search: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/user/search`,
      { params: { "filter[search]": search }, cache: true, ...cfg })),

  addUser: (runetId: number | string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/user/pull`, { runetId },
      { ...cfg })),
})


export default {
  data: () => ({
    list: [
      { id: 1, link: "" },
      { id: 2, link: "" },
      { id: 3, link: "" },
      { id: 4, link: "" },
      { id: 5, link: "" },
      { id: 6, link: "" },
    ],
  }),
}
